/** @jsxImportSource @emotion/react */
import { formatElevatorFormDataToApi, generateAddressObject } from '@shopopop/backoffice-frontend-utils';
import { RecipientDeliveryAddress } from '@shopopop/backoffice-ui';
import { useTransformElevatorData } from '@shopopop/react-hooks';
import { Card, Col, Form, Row, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';
import BreadcrumbContext from '../../../context/BreadcrumbContext';
import { NotificationCenterContext } from '../../../context/NotificationCenterContext';
import useAddressSearch from '../../../hooks/useAddressSearch';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useRouter } from '../../../hooks/useRouter';
import { readHistory } from '../../../services/apiEndPoints/delivery/detail/readHistory';
import { readIncident } from '../../../services/apiEndPoints/delivery/incident';
import addressFormatter from '../../../utils/addressFormatter';
import { getShiftedCountsDateAndSlot } from '../../../utils/getShiftedCountsDateAndSlot';
import AddressCard from '../../Addresses/AddressCard';
import Archivage from '../../Archivage/Archivage';
import DeliveryIncidentDrawer from '../../DeliveryIncident/DeliveryIncidentDrawer';
import TouchableView from '../../DeviceDetect/TouchableView';
import UntouchableView from '../../DeviceDetect/UntouchableView';
import StatusErrorWrapper from '../../Wrapper/StatusErrorWrapper';
import Delivery from './Delivery/Delivery';
import Recipient from './Delivery/Recipient';
import UpdateOrder from './Delivery/UpdateOrder';
import UpdateShopper from './Delivery/UpdateShopper';
import EditDelivery from './EditDelivery/EditDelivery';
import History from './History/History';
import Save from './Save';
import UpdateDelivery from './UpdateDelivery';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

/**
 * Delivery Detail
 * @return {String}
 */
const Detail = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const saveBarRef = useRef();
  const deliveryId = router.query.id;

  const { transformedElevatorData } = useTransformElevatorData();

  const updateDeliveryFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const getDeliveryFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const getPushesData = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  const pushFetch = useFetch(process.env.REACT_APP_API_BO, {
    cachePolicy: 'no-cache',
  });
  // Context
  const notification = useContext(NotificationCenterContext);
  const contextBreadcrumb = useContext(BreadcrumbContext);
  // State
  const [delivery, setDelivery] = useState({});
  const [selectedDriveId, setSelectedDriveId] = useState();
  const [statusError, setStatusError] = useState(200);
  const [searchUser, setSearchUser] = useState(null);
  const [payload, setPayload] = useState(null);
  const [staticDelivery, setStaticDelivery] = useState(true);
  const [activeTab, setActiveTab] = useState(router.query.tab);
  const [historyDelivery, setHistoryDelivery] = useState([]);
  const [pushesDelivery, setPushesDelivery] = useState([]);
  const [deliveryShifted, setDeliveryShifted] = useState(0); // Remove when this old feature is removed (BetaFeature)
  const [deliveryShiftedCount, setDeliveryShiftedCount] = useState({});
  const [deliveryShopperCancelled, setDeliveryShopperCancelled] = useState(0);
  const [pushAtUpdate, setPushAtUpdate] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  // Incident states
  const [incident, setIncident] = useState(null);
  const [incidentsHistory, setIncidentsHistory] = useState([]);
  // Address states
  const [address, setAddress] = useState({});
  const [originalAddress, setOriginalAddress] = useState(null);
  const [placeId, setPlaceId] = useState(null);

  useDocumentTitle(delivery ? `${t('URL_DELIVERY_DETAIL')} ${delivery.recipient?.first_name} ${delivery.recipient?.last_name}` : t('URL_DELIVERY_DETAIL'));

  const getDeliveriesDetails = async() => {
    await getDeliveryFetch.get(`/deliveries/details/${deliveryId}`);
    if (getDeliveryFetch.response.status === 200) {
      setDelivery(getDeliveryFetch.response.data);
      const { validated, status } = getDeliveryFetch.response.data;
      const shouldSetStaticDeliveryFalse = !validated || status === 6 || status === 8;
      setStaticDelivery(!shouldSetStaticDeliveryFalse);
      setSelectedDriveId(getDeliveryFetch.response.data.drive_id);
      contextBreadcrumb.setInfoBreadcrumb(getDeliveryFetch.response.data);
      setAddress({
        address: addressFormatter({
          address: getDeliveryFetch.response.data.recipient.address.address,
          zip_code: getDeliveryFetch.response.data.recipient.address.zip_code,
          city: getDeliveryFetch.response.data.recipient.address.city,
        }),
        floor: getDeliveryFetch.response.data.recipient.address.floor,
        elevator: getDeliveryFetch.response.data.recipient.address.elevator,
        additionalInfo: getDeliveryFetch.response.data.recipient.address.additional_info,
        original_address: getDeliveryFetch.response.data.recipient.address.original_address
      });
    } else {
      setStatusError(getDeliveryFetch.response.status);
    }
  };

  const handleAddressSelect = ({ placeId, address }) => {
    setPlaceId(placeId);
    setOriginalAddress(address);
  };

  const onAddressSearch = useAddressSearch();

  /**
   * getHistory - Get the history of the delivery
   * @param {number} deliveryId - The delivery id
   * @return {Promise<void>}
   */
  const getHistory = async() => {
    setHistoryLoading(true);
    readHistory(deliveryId)
      .then((response) => {
        if (response.type === 'success') {
          setHistoryLoading(false);
          setHistoryDelivery(response.data.filter((historyDelivery) => historyDelivery.type !== 'INCIDENT' && historyDelivery.type !== 'INCIDENT_MINOR' && historyDelivery.type !== 'INCIDENT_MAJOR'));
          setDeliveryShifted(response.data.filter((historyDelivery) => historyDelivery.type === 'SHIFTED').length); // Remove when this old feature is removed (BetaFeature)
          setDeliveryShopperCancelled(response.data.filter((historyDelivery) => historyDelivery.type === 'CANCELED').length);
        }
      });
  };

  useEffect(() => {
    setDeliveryShiftedCount(getShiftedCountsDateAndSlot(historyDelivery.filter((historyDelivery) => historyDelivery.type === 'SHIFTED')));
  }, [historyDelivery]);

  /**
   * getPushes - Get the pushes of the delivery
   * @param {number} deliveryId - The delivery id
   * @return {Promise<void>}
   */
  const getPushes = async() => {
    await getPushesData.get(`/deliveries/${deliveryId}/pushes`);
    if (getPushesData.response.status === 200) {
      setPushesDelivery(getPushesData.response.data);
    } else {
      console.error(getPushesData.response.status);
    }
  };

  const readIncidentsHistory = () => {
    readIncident(deliveryId)
      .then((response) => {
        if (response.type === 'success') {
          const historyDataBuffer = response.data.flatMap((incidentObject) => {
            if (incidentObject.incidentClosed) {
              const objectIncident = {
                callerFirstName: incidentObject.callerFirstName,
                callerId: incidentObject.callerId,
                callerLastName: incidentObject.callerLastName,
                comment: incidentObject.comment,
                createdAt: incidentObject.createdAt,
                deliveryId: incidentObject.deliveryId,
                deliveryClosedRequested: incidentObject.deliveryClosedRequested,
                incidentReasons: incidentObject.incidentReasons,
                incidentTypes: incidentObject.incidentTypes,
                kilometerRange: incidentObject.kilometerRange,
                type: 'DELIVERY_INCIDENT' };
              const objectCanceled = {
                callerFirstName: incidentObject.updateByFirstName,
                callerId: incidentObject.updatedBy,
                callerLastName: incidentObject.updateByLastName,
                createdAt: incidentObject.updatedAt,
                tips: incidentObject.tips,
                type: 'INCIDENT_CANCELED',
              };
              return [objectIncident, objectCanceled];
            } else {
              return [{ ...incidentObject, type: 'DELIVERY_INCIDENT' }];
            }
          });
          setIncidentsHistory(historyDataBuffer);
          const haveOpenIncident = response.data.filter((incident) => !incident.incidentClosed);
          haveOpenIncident.length === 0 ? setIncident(null) : setIncident(haveOpenIncident[0]);
          getDeliveriesDetails();
        }
      });
  };

  useEffect(() => {
    getDeliveriesDetails();
    getHistory();
    getPushes();
    readIncidentsHistory();
  }, []);

  // on utilise un useEffect pour mettre à jour l'historique uniquement lorsqu'il est affiché et si la livraison est modifiée
  useEffect(() => {
    if (activeTab === 'history') {
      getHistory();
    }
  }, [delivery, activeTab]);

  // on utilise un useEffect pour mettre à jour le payload de delivery_end quand delivery_start est modifié, il y avait un décalage dans le onfinish
  useEffect(() => {
    if (payload?.delivery_start) {
      setPayload({ ...payload,
        delivery_end: dayjs(`${dayjs(form.getFieldValue('deliveryDatePickerDay')).format('YYYY-MM-DD')} ${form.getFieldValue('deliveryEnd')}`),
      });
    }
  }, [payload?.delivery_start]);

  // on effectue un swich sur le nom de la clé du form antd pour mettre à jour le payload
  const updatePayload = (info) => {
    saveBarRef.current.showBar();
    switch (Object.keys(info)[0]) {
    case 'tips':
      setPayload({
        ...payload, tips: info.tips,
      });
      break;
    case 'sizeCommand':
      setPayload({
        ...payload, transport_size: info.sizeCommand,
      });
      break;
    case 'reference':
      setPayload({
        ...payload, reference: info.reference,
      });
      break;
    case 'storeComment':
      setPayload({
        ...payload, additional_info: info.storeComment,
      });
      break;
    case 'orderAmount':
      setPayload({
        ...payload, ord_value: info.orderAmount,
      });
      break;
    case 'articlesAmount':
      setPayload({
        ...payload, nbr_article: info.articlesAmount,
      });
      break;
    default:
      break;
    }
  };

  const sendPush = async() => {
    await pushFetch.post('/deliveries/notifications', [deliveryId]);
    if (pushFetch.response) {
      saveBarRef.current.hideBar();
      setPushAtUpdate(!pushAtUpdate);
      notification.push('success', t('PUSH_SUCCES_ONE'));
    }
  };

  const updateDelivery = async(e) => {
    let finalPayload = payload;

    const addressFormValues = {
      address: e.address,
      floor: e.floor,
      elevator: formatElevatorFormDataToApi(e.elevator),
      additional_info: e.additional_info,
    };

    const hasValidValue = Object.values(addressFormValues).some((value) => value !== undefined);

    if (hasValidValue) {
      finalPayload = {
        ...finalPayload,
        address: generateAddressObject({
          addressFormValues,
          addressInitialValues: address,
          addressPlaceId: placeId
        }),
      };
    }

    if (pushAtUpdate) {
      sendPush();
    }

    if (e.hourStart && e.hourEnd) {
      const newDeliveryStart = dayjs(e.hourStart).second(0);
      const newDeliveryEnd = dayjs(e.hourEnd).second(0);
      if (!newDeliveryStart.isSame(dayjs(delivery.delivery_start), 'minute')) {
        finalPayload = {
          ...finalPayload,
          delivery_start: newDeliveryStart.toISOString(),
          delivery_end: newDeliveryEnd.toISOString(),
        };
      }
      if (!newDeliveryEnd.isSame(dayjs(delivery.delivery_end), 'minute')) {
        finalPayload = {
          ...finalPayload,
          delivery_end: newDeliveryEnd.toISOString(),
        };
      }
    }

    await updateDeliveryFetch.put(`/deliveries/${deliveryId}`, finalPayload);
    const responseData = updateDeliveryFetch.response.data;
    if (updateDeliveryFetch.response.ok) {
      notification.push('success', t('DELIVERY_CHANGED_SUCCESSFULLY'));

      if (!dayjs(responseData.delivery_start).isSame(dayjs(delivery.delivery_start), 'minute') || !dayjs(responseData.delivery_end).isSame(dayjs(delivery.delivery_end), 'minute')) {
        setDeliveryShifted(deliveryShifted + 1); // Remove when this old feature is removed (BetaFeature)
        setHistoryDelivery([
          ...historyDelivery,
          {
            additional_info:
            { old_end_date: delivery.delivery_end,
              old_start_date: delivery.delivery_start,
              new_end_date: responseData.delivery_end,
              new_start_date: responseData.delivery_start,
            },
            date: dayjs().utc().format(),
            type: 'SHIFTED',
          },
        ]);
      }

      saveBarRef.current.hideBar();

      setAddress({
        ...addressFormValues,
        additionalInfo: addressFormValues.additional_info,
        original_address: addressFormValues.address,
      });

      setPlaceId(null);
      setPayload(null);

      setDelivery({
        ...delivery,
        tips: responseData.tips,
        delivery_start: responseData.delivery_start,
        delivery_end: responseData.delivery_end,
        withdrawal_start: responseData.withdrawal_start,
        withdrawal_end: responseData.withdrawal_end,
        distance: responseData.distance_drive_dest,
        drive_id: responseData.drive_id,
        address_id: responseData.address_id,
        details: {
          ...delivery.details,
          transport_size: e.sizeCommand, // Donnée non présente dans le retour de l'API, on se fie à la valeur côté front.
          reference: responseData.reference,
          additional_info: responseData.additional_info,
          ord_value: responseData.ord_value,
          nbr_article: responseData.nbr_article,
        },
      });
    } else {
      if (updateDeliveryFetch.response.status === 400) {
        if (responseData.errors[0].message === 'DELIVERY_DATE_TOO_CLOSE') {
          return notification.push('error', t('DELIVERY_SLOT_EXPIRED'));
        }

        if (responseData.errors[0].message === 'DELIVERY_DISTANCE_TOO_LONG') {
          return notification.push('error', t('UPDATING_ADDRESS_Bad Request'));
        }
      }
      if (updateDeliveryFetch.response.status === 404) {
        notification.push('error', t('DELIVERY_NOT_FOUND'));
      }
      if (updateDeliveryFetch.response.status === 409) {
        notification.push('error', t('DELIVERY_ALREADY_EXISTS'));
      }
      if (updateDeliveryFetch.response.status === 503) {
        notification.push('error', t('EXTERNAL_SERVICE_ERROR'));
      }
    }
  };

  const onClickTab = (key) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };

  useEffect(() => {
    if (address && form) {
      form.setFieldsValue({
        address: addressFormatter(address),
        floor: address.floor,
        elevator: transformedElevatorData(address.elevator),
        additional_info: address.additionalInfo,
      });
    }
  }, [address, form]);

  const renderContentAddress = () => {
    if (!Object.keys(address).length) {
      return <Card loading={true} title={t('RCP_ADDRESS_TITLE')} />;
    }

    if (staticDelivery) {
      return (
        <RecipientDeliveryAddress
          form={form}
          originalAddress={addressFormatter({
            address: address.address,
            zip_code: address.zip_code,
            city: address.city,
          })}
          onAddressSearch={onAddressSearch}
          onAddressSelect={handleAddressSelect}
          showOriginalAddress={originalAddress ?? address.original_address ?? '-'}
        />
      );
    }

    return (
      <AddressCard
        address={address}
        userId={delivery?.recipient?.id}
        loading={getDeliveryFetch.loading}
      />
    );
  };

  const tabsItems = [
    {
      label: t('DELIVERY_STEP'),
      key: 'delivery',
      children: (
        <Row gutter={[20, 20]}>
          {staticDelivery ?
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Delivery
                delivery={delivery}
                push={pushesDelivery?.length}
                deliveryShifted={deliveryShifted}
                deliveryShiftedDateCount={deliveryShiftedCount.shiftedDateCount}
                deliveryShiftedSlotCount={deliveryShiftedCount.shiftedSlotCount}
                deliveryShopperCancelled={deliveryShopperCancelled}
                loading={getDeliveryFetch.loading}
              />
            </Col> : <></>
          }
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Recipient delivery={delivery} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {renderContentAddress()}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <UpdateOrder
              form={form}
              delivery={delivery}
              setSelectedDriveId={setSelectedDriveId}
              selectedDriveId={selectedDriveId}
              staticDelivery={staticDelivery}
              payload={payload}
              setPayload={setPayload}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {(delivery.validated || (!delivery.validated && delivery.shopper)) &&
              <UpdateShopper delivery={delivery} setDelivery={setDelivery} searchUser={searchUser} setSearchUser={setSearchUser} staticDelivery={staticDelivery}/>
            }
          </Col>
        </Row>
      ),
    },
    {
      label: t('HISTORY_DELIVERYDETAIL'),
      key: 'history',
      children: (
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <History
              delivery={delivery}
              history={historyDelivery}
              pushes={pushesDelivery}
              incident={incidentsHistory}
              loading={
                updateDeliveryFetch.loading ||
                getDeliveryFetch.loading ||
                historyLoading ||
                getPushesData.loading
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const DeliveryActions = () => {
    return (
      delivery.validated ?
        <div css={{ display: 'flex', gap: 10 }}>
          {delivery.status === 6 && <EditDelivery delivery={delivery} setDelivery={setDelivery}/>}
          {
            delivery.status >= 3 &&
            delivery.status !== 8 &&
            <DeliveryIncidentDrawer
              incident={incident}
              setIncident={setIncident}
              setDelivery={setDelivery}
              driveName={delivery.details.drive_name}
              updateIncidentsHistory={readIncidentsHistory}
              deliveryStatus={delivery.status}
            />
          }
          <Archivage
            delivery={delivery}
            setDelivery={setDelivery}
            setStaticDelivery={setStaticDelivery}
          />
        </div> : <></>
    );
  };

  return (
    <StatusErrorWrapper statusError={statusError}>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(e) => {
          updatePayload(e);
        }}
        onFinish={(e) => updateDelivery(e)}
        initialValues={address && form}
      >
        <TouchableView>
          <DeliveryActions />
          <UpdateDelivery
            form={form}
            touch={true}
            delivery={delivery}
            loading={getDeliveryFetch.loading}
            deliveryShopperCancelled={deliveryShopperCancelled}
            setDelivery={setDelivery}
            setSearchUser={setSearchUser}
            setStaticDelivery={setStaticDelivery}
            setPushAtUpdate={setPushAtUpdate}
            setDeliveryShopperCancelled={setDeliveryShopperCancelled}
            pushAtUpdate={pushAtUpdate}
            push={pushesDelivery?.length}
            incident={incident}
          />
        </TouchableView>

        <div>
          <div className='dual-panel-wrapper'>
            <div>
              <UntouchableView>
                <UpdateDelivery
                  form={form}
                  delivery={delivery}
                  loading={getDeliveryFetch.loading}
                  deliveryShopperCancelled={deliveryShopperCancelled}
                  setDelivery={setDelivery}
                  setSearchUser={setSearchUser}
                  setStaticDelivery={setStaticDelivery}
                  setPushAtUpdate={setPushAtUpdate}
                  setDeliveryShopperCancelled={setDeliveryShopperCancelled}
                  pushAtUpdate={pushAtUpdate}
                  push={pushesDelivery?.length}
                  incident={incident}
                />
              </UntouchableView>
            </div>
            <div>
              <Tabs
                items={tabsItems}
                animated={true}
                defaultActiveKey={activeTab}
                onChange={onClickTab}
                tabBarExtraContent={
                  <UntouchableView>
                    <DeliveryActions />
                  </UntouchableView>
                }
              />
            </div>
          </div>

          <Save
            ref={saveBarRef}
            form={form}
            isLoading={updateDeliveryFetch.loading}
            setDeliveryAddress={setAddress}
            delivery={delivery}
            setSelectedDriveId={setSelectedDriveId}
            setPushAtUpdate={setPushAtUpdate}
            setPlaceId={setPlaceId}
          />
        </div>
      </Form>
    </StatusErrorWrapper>
  );
};

export default Detail;
