import { buildCustomHtml } from '@shopopop/backoffice-frontend-utils';
import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { mapDeliveryTransport } from '../../../../../apps/external/src/utils/transformData/transformTransport.ts';
import { OrderSummaryCardProps } from '../../interfaces/OrderInfoCardProps';

const { Text } = Typography;

function OrderSummaryCard({ orderRef, numberOfPacks, orderAmount, orderTransportation, orderAdditionalInfo, orderCurrencySymbol }: Readonly<OrderSummaryCardProps>) {
  const { t } = useTranslation();
  const formattedOrderAdditionalInfo = orderAdditionalInfo?.trim().replace(/(?:\r\n|\r|\n)/g, '<br />');

  const formattedOrderAdditionalInfoHtml = buildCustomHtml(formattedOrderAdditionalInfo);

  return (
    <Card title={t('RECAP_ORDER_INFO_TITLE')}>
      <Row gutter={[0, 16]}>
        <Col xs={24} lg={8}>
          <Text type="secondary">{t('ORDER_REFERENCE')} : </Text>
          <Text>{orderRef}</Text>
        </Col>
        <Col xs={24} lg={8}>
          <Text type="secondary">{t('ORDER_VALUE')} : </Text>
          <Text>{orderAmount} {orderCurrencySymbol}</Text>
        </Col>
        <Col xs={24} lg={8}>
          <Text type="secondary">{t('PACK_NB')} : </Text>
          <Text>{numberOfPacks}</Text>
        </Col>
        <Col xs={24}>
          <Text type="secondary">{t('ORDER_TRANSPORTATION')} : </Text>
          <Text>{orderTransportation && t(mapDeliveryTransport(orderTransportation))}</Text>
        </Col>
        <Col xs={24}>
          <Text type="secondary">
            {t('ORDER_ADDITIONAL_INFO')} : {!orderAdditionalInfo && <Text>-</Text>}
          </Text>
          {orderAdditionalInfo && <Text>{formattedOrderAdditionalInfoHtml}</Text>}
        </Col>
      </Row>
    </Card>
  );
}

export default OrderSummaryCard;
