interface AddressFormValues {
  floor?: number;
  elevator?: boolean | null;
  additional_info?: string | null;
  address?: string;
}

interface AddressInitialValues {
  floor?: number | null;
  elevator?: boolean | null;
  additionalInfo?: string | null;
  address?: string | null;
}

interface NewAddress {
  floor: number | null;
  elevator: boolean | null;
  additional_info: string | null | undefined;
  originalAddress: string | null | undefined;
  placeId?: string | null;
}

function areValuesEqual(value1: unknown, value2: unknown): boolean {
  if (typeof value1 === 'boolean' && typeof value2 === 'number') {
    return value1 === (value2 === 1);
  }
  if (typeof value1 === 'number' && typeof value2 === 'boolean') {
    return (value1 === 1) === value2;
  }
  return value1 === value2;
}

function generateAddressObject({
  addressFormValues = {},
  addressInitialValues = {
    floor: null,
    elevator: null,
    additionalInfo: null,
    address: null,
  },
  addressPlaceId,
}: {
  addressFormValues?: AddressFormValues;
  addressInitialValues?: AddressInitialValues;
  addressPlaceId?: string;
}): Partial<NewAddress> | undefined {
  const address: Partial<NewAddress> = {};
  let hasChanges = false;

  if (addressFormValues.floor !== undefined && !areValuesEqual(addressFormValues.floor, addressInitialValues.floor)) {
    address.floor = addressFormValues.floor;
    hasChanges = true;
  }

  if (addressFormValues.elevator !== undefined && !areValuesEqual(addressFormValues.elevator, addressInitialValues.elevator)) {
    address.elevator = addressFormValues.elevator;
    hasChanges = true;
  }

  if (addressFormValues.additional_info !== undefined && !areValuesEqual(addressFormValues.additional_info, addressInitialValues.additionalInfo)) {
    address.additional_info = addressFormValues.additional_info;
    hasChanges = true;
  }

  if (addressPlaceId) {
    address.originalAddress = addressFormValues.address;
    address.placeId = addressPlaceId;
    hasChanges = true;
  }

  return hasChanges ? address : undefined;
}

export default generateAddressObject;
