import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Card, Col, Form, Input, InputNumber, Row, Tooltip, theme } from 'antd';
import { alphanumericWithSpecialChars } from 'external/src/utils/regex/inputRegex.ts';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { InputCurrency } from '../../index.ts';

function OrderInfoCard({ currencySymbol }: { currencySymbol?: string }): ReactNode {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  return (
    <Card title={t('ORDER_INFO_TITLE')}>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('ORDER_REFERENCE')}
            name="reference"
            rules={[
              { required: true, message: t('ORDER_REF_REQUIRED') },
              { max: 50, message: t('ORDER_REF_LIMIT_SIZE') },
              {
                pattern: alphanumericWithSpecialChars,
                message: t('INVALID_VALUE'),
              },
            ]}
          >
            <Input data-testid="order-ref-input" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={
              <span>
                {t('PACK_NB')}
                <Tooltip title={t('TOOLTIP_PACKS')}>
                  <QuestionCircleTwoTone twoToneColor={token.colorPrimary} style={{ marginLeft: 4 }} />
                </Tooltip>
              </span>
            }
            name="pack"
            rules={[
              { required: true, message: t('PACKS_NBR_REQUIRED') },
              {
                type: 'number',
                min: 0,
                max: 40,
                message: t('PACKS_LIMIT_NBR'),
              },
              {
                pattern: /^[0-9]+$/,
                message: t('INVALID_VALUE'),
              },
            ]}
          >
            <InputNumber data-testid="packs-input" style={{ width: '100%' }} min={0} inputMode="numeric" decimalSeparator="," />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <InputCurrency
            label={t('ORDER_VALUE')}
            name="amount"
            currencySymbol={currencySymbol}
            rules={[
              { required: true, message: t('ORDER_VALUE_REQUIRED') },
              { type: 'number', max: 999999.99, message: t('ORDER_VALUE_TOO_HIGH') },
            ]}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default OrderInfoCard;
