/** @jsxImportSource @emotion/react */
import { ArrowRightOutlined, WarningFilled } from '@ant-design/icons';
import { Button, ConfigProvider, Table, Tag, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRouter } from '../../../hooks/useRouter';
import { readCotransportorDeliveries } from '../../../services/apiEndPoints/user/readUser';
import { customizeRenderEmpty } from '../../../services/customizeRenderEmpty';
import { findStatusDeliveries } from '../../../utils/findStatusDeliveries';
import { toLocaleString } from '../../../utils/toLocaleString';
import { toLocaleStringHourandMinutes } from '../../../utils/toLocaleStringHourandMinutes';
import ShopTableRow from '../../Deliveries/ShopTableRow';
import SkeletonTableLoader from '../../SkeletonLoaders/SkeletonTableLoader';

/**
 * Renders a list of shopper deliveries.
 *
 * @param {Object} props - The count and setCount properties.
 * @param {number} props.count - The total count of shopper deliveries.
 * @param {function} props.setCount - A function to update the count of shopper deliveries.
 * @return {Element} - The React element representing the list of shopper deliveries.
 */
function ShopperDeliveriesList({ count, setCount }) {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const router = useRouter();
  const shopperId = router.query.id;
  const [deliveries, setDeliveries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCotransportorDeliveries();
  }, [currentPage]);

  const getCotransportorDeliveries = async() => {
    setLoading(true);
    readCotransportorDeliveries({
      userId: shopperId,
      page: currentPage,
      sort: 'delivery_start',
      order: 'DESC',
    }).then((response) => {
      setLoading(false);
      setCount(response.data.count);
      setDeliveries(response.data.list);
    });
  };

  const columns = [
    {
      title: t('DELIVERY_TABLE_TRADE'),
      dataIndex: 'trade',
      render: (trade) => {
        return (
          <ShopTableRow
            logoSrc={process.env.REACT_APP_STATIC_BUCKET + trade.logo + '.webp'}
            shopName={trade.name}
            shopCity={trade.address?.zip_code + ' ' + trade.address?.city}
          />
        );
      },
    },
    {
      title: t('DELIVERY'),
      dataIndex: 'delivery',
      render: (date) => {
        return date ? (
          <div>
            <div>{toLocaleString({ date: date.delivery_start, fullDatetime: true })}</div>
            <span>
              {toLocaleStringHourandMinutes(date.delivery_start)} - {toLocaleStringHourandMinutes(date.delivery_end)}
            </span>
          </div>
        ) : null;
      },
    },
    {
      title: t('USER_CUSTOMER'),
      dataIndex: 'client',
      render: (client) => {
        return (
          <>
            {`${client.first_name} ${client.last_name}`}
          </>
        );
      },
    },
    {
      title: t('DELIVERY_TABLE_STATE'),
      dataIndex: 'status',
      render: (status) => {
        const statusDetail = findStatusDeliveries(status.status);
        return (
          <Tag style={statusDetail.style}> {t(statusDetail.message)}</Tag>
        );
      },
    },
    {
      title: t('DELIVERY_TABLE_TIPS'),
      dataIndex: 'tip',
    },
    {
      key: 'incident_icon',
      dataIndex: 'incident_icon',
      width: 21,
      render: (incident) => (
        incident && <WarningFilled style={{ color: token.colorError, fontSize: '15px' }} />
      ),
    },
    {
      key: 'button_link',
      dataIndex: 'button_link',
      width: 60,
      render: ({ deliveryId }) => (
        <Link to={`/delivery/${deliveryId}`}>
          <Button type='text' shape="circle" icon={<ArrowRightOutlined />}/>
        </Link>
      ),
    },
  ];

  return (
    <SkeletonTableLoader loading={loading} columns={columns}>
      <ConfigProvider renderEmpty={() => customizeRenderEmpty({
        refreshLink: () => getCotransportorDeliveries(),
      })}>
        <Table
          columns={columns}
          rowClassName={(_, index) => (deliveries[index]?.validated === 0 && deliveries[index]?.status !== 7) ? 'validated' : ''}
          dataSource={deliveries?.map((delivery, index) => {
            return {
              key: index,
              trade: {
                logo: delivery.trade.trade_id,
                name: delivery.trade.name,
                address: {
                  zip_code: delivery.trade.address.zip_code,
                  city: delivery.trade.address.city,
                },
              },
              delivery: { delivery_end: delivery.delivery_end, delivery_start: delivery.delivery_start },
              client: { first_name: delivery.client.first_name, last_name: delivery.client.last_name },
              status: { status: delivery.status, popoverContent: null },
              tip: `${delivery.tips}€`,
              incident_icon: delivery?.incident,
              button_link: { deliveryId: delivery?.id },
            };
          })}
          pagination={{
            total: count,
            showLessItems: true,
            defaultCurrent: currentPage,
            showSizeChanger: false,
            defaultPageSize: 5,
            size: 'small',
          }}
          onChange={(pagination) => {
            setCurrentPage(pagination.current);
          }}
          css={{
            '.validated': {
              'td:first-of-type': {
                borderLeft: `3px solid ${token.colorWarningBorder}`,
              },
            },
          }}
        />
      </ConfigProvider>
    </SkeletonTableLoader>
  );
}

export default ShopperDeliveriesList;
