import styled from '@emotion/styled';
import { buildCustomHtml, DEFAULT_PAGINATION, formatDateFullDate } from '@shopopop/backoffice-frontend-utils';
import { DeliveryListTabNames, useScreenSize } from '@shopopop/react-hooks';
import { Badge, Card, Flex, theme } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateFilters, DateRange, DeliveriesListCardProps } from '../../interfaces/DeliveriesListCardProps';
import DeliveriesDateFilter from '../DateSelector/DeliveriesDateFilter';
import DeliveriesTabContent from '../Deliveries/list/DeliveriesTabContent';
import dayjs from 'dayjs';

const DATE_START_PARAM = 'withdrawal_start_utc';
const DATE_END_PARAM = 'withdrawal_end_utc';

export default function DeliveriesListCard({ counts, deliveries, loading, onBoardingRef }: Readonly<DeliveriesListCardProps>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    token: { colorPrimary, colorPrimaryBg, colorError, colorErrorBg, colorTextSecondary, colorBorder },
  } = theme.useToken();
  const { tabletMode } = useScreenSize();
  const searchParams = new URLSearchParams(location.search);

  const [activeTabKey, setActiveTabKey] = useState((searchParams.get('tab') as DeliveryListTabNames) || DeliveryListTabNames.Schedule);
  const [pageSize, setPageSize] = useState(Number(searchParams.get('per_page')) || DEFAULT_PAGINATION.pageSize);
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get('page')) || DEFAULT_PAGINATION.current);
  const [totalDeliveries, setTotalDeliveries] = useState(0);

  const { scheduledCount, inDeliveryCount, deliveredCount, interruptedCount, totalCount } = useMemo(() => {
    const total = Object.values(counts ?? {}).reduce((total, val) => total + val, 0);

    return {
      scheduledCount: counts?.schedule ?? 0,
      inDeliveryCount: counts?.inProgress ?? 0,
      deliveredCount: counts?.deliveredAndFinished ?? 0,
      interruptedCount: counts?.interrupted ?? 0,
      totalCount: total,
    };
  }, [counts]);

  const dateFilters: DateFilters = useMemo(() => {
    const dateStart = searchParams.get(DATE_START_PARAM);
    const dateEnd = searchParams.get(DATE_END_PARAM);

    const formattedStart = dateStart ? formatDateFullDate(dateStart) : dateStart;
    const formattedEnd = dateEnd ? formatDateFullDate(dateEnd) : dateEnd;

    // #region set date filter mode
    const start = dayjs(dateStart);
    const end = dayjs(dateEnd);
    const mode: 'date' | 'range' = end.diff(start, 'hour') <= 24 ? 'date' : 'range';
    // #endregion

    return { mode: mode, range: [formattedStart, formattedEnd] };
  }, [searchParams]);

  useEffect(() => {
    const activeTab = (searchParams.get('tab') as DeliveryListTabNames) || DeliveryListTabNames.Schedule;

    setCurrentPage(Number(searchParams.get('page')) || DEFAULT_PAGINATION.current);
    setPageSize(Number(searchParams.get('per_page')) || DEFAULT_PAGINATION.pageSize);
    setTotalDeliveries(counts?.[activeTab] ?? 0);
    setActiveTabKey(activeTab);
  }, [searchParams]);

  useEffect(() => {
    const tabsElement = document.querySelector('.ant-tabs-nav-list');
    if (tabsElement) {
      (onBoardingRef.tabs as React.MutableRefObject<HTMLDivElement>).current = tabsElement as HTMLDivElement;
    }
  }, [onBoardingRef]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('per_page', pageSize.toString());
    searchParams.set('page', page.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const extraTabMessage = buildCustomHtml(
    t('DISPLAY_DELIVERY_DELIVERED_NBR', {
      deliveredCount,
      totalCount,
    }),
    [
      {
        key: '[NBR_DELIVERY_DELIVERED]',
        value: deliveredCount.toString(),
      },
      {
        key: '[TOTAL_DELIVERIES]',
        value: totalCount.toString(),
        styles: `color:${colorTextSecondary}`,
      },
    ],
  );

  const handleTabChange = (key: string) => {
    setActiveTabKey(key as DeliveryListTabNames);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('status');
    searchParams.set('tab', key);
    searchParams.set('page', DEFAULT_PAGINATION.current.toString());
    searchParams.set('per_page', DEFAULT_PAGINATION.pageSize.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const handleDateChange = ([startDate, endDate]: DateRange) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(DATE_START_PARAM, startDate!);
    searchParams.set(DATE_END_PARAM, endDate!);
    navigate(`?${searchParams.toString()}`);
  };

  const tabList = [
    {
      key: DeliveryListTabNames.Schedule,
      count: scheduledCount,
      label: t('DELIVERY_LIST_TAB1'),
    },
    {
      key: DeliveryListTabNames.InProgress,
      count: inDeliveryCount,
      label: t('DELIVERY_LIST_TAB2'),
    },
    {
      key: DeliveryListTabNames.DeliveredAndFinished,
      count: deliveredCount,
      label: t('DELIVERY_LIST_TAB3'),
    },
    {
      key: DeliveryListTabNames.Interrupted,
      count: interruptedCount,
      label: t('DELIVERY_LIST_TAB4'),
      isError: true,
    },
  ].map(({ key, count, label, isError }) => ({
    key,
    label: (
      <Flex gap={10} align="center">
        {label}
        <Badge
          count={count}
          style={{
            backgroundColor: activeTabKey === key ? colorPrimary : isError ? colorErrorBg : colorPrimaryBg,
            color: activeTabKey === key ? colorPrimaryBg : isError ? colorError : colorPrimary,
          }}
          showZero={true}
        />
      </Flex>
    ),
  }));

  return (
    <StyledDeliveriesListCard
      borderColor={colorBorder}
      style={{ width: '100%' }}
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={handleTabChange}
      tabProps={{ tabBarGutter: 30, size: 'small' }}
      title={
        <Flex justify="space-between" align="center">
          <DeliveriesDateFilter ref={onBoardingRef.dateFilters} onChange={handleDateChange} dateFilters={dateFilters} />
          {tabletMode ? null : extraTabMessage}
        </Flex>
      }
    >
      <DeliveriesTabContent
        paginationProps={{ onChange: handlePaginationChange, page: currentPage, size: pageSize }}
        total={totalDeliveries}
        currentTab={activeTabKey}
        deliveries={deliveries}
        loading={loading}
      />
    </StyledDeliveriesListCard>
  );
}

const StyledDeliveriesListCard = styled(Card)<{ borderColor: string }>`
  .ant-tabs .ant-tabs-nav-operations {
    display: none;
  }

  > .ant-card-head {
    padding: 0 !important;
    border-bottom: 0;
  }

  > .ant-card-head > * {
    padding: 0 20px;
  }

  > .ant-card-head > .ant-card-head-wrapper {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ borderColor }) => borderColor};
  }
`;
