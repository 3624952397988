import styled from '@emotion/styled';
import { addDays, isBefore } from '@shopopop/backoffice-frontend-utils';
import { useScreenSize } from '@shopopop/react-hooks';
import { Col, DatePicker, Row, Select, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFilters, DateRange, DateSelectionMode } from '../../interfaces/DeliveriesListCardProps';

const { Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const commonPickerProps = {
  format: 'LL',
  allowClear: false,
  style: { width: '100%' },
} as const;

interface DeliveriesDateFilterProps {
  dateFilters: DateFilters;
  onChange: (data: DateRange) => void;
}

const formatDateIso = (date: string) => dayjs(date, commonPickerProps.format).toISOString();
const formatValueForParent = (range: DateRange, mode: DateSelectionMode): DateRange => {
  if (mode === 'range') return range.map((date) => formatDateIso(date!)) as DateRange;

  const [startDate] = range;
  return [formatDateIso(startDate!), formatDateIso(addDays(startDate, 1, commonPickerProps.format)!)];
};
const formatValueForPicker = (value: string | null): Dayjs | null => (value ? dayjs(value, commonPickerProps.format) : null);
const getMinDate = (date?: string | null): Dayjs => {
  const formattedDate = formatValueForPicker(date ?? null);

  return formattedDate ?? dayjs().subtract(3, 'months');
};

const DeliveriesDateFilter = forwardRef(({ dateFilters: { mode, range }, onChange }: DeliveriesDateFilterProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { tabletMode, mobileMode } = useScreenSize();

  const [selectorMode, setSelectorMode] = useState<DateSelectionMode>(mode);

  useEffect(() => {
    setSelectorMode(mode);
  }, [mode]);

  const { handleModeChange, handleStartChange, rangeStart } = useMemo(() => {
    const rangeStart = formatValueForPicker(range[0]);

    // ensure range is valid when range is composed of two different selectors
    const handleStartChange = (_: unknown, isoDate: string | string[]) => {
      const dateString = isoDate as string;
      const [, rangeEnd] = range;
      const newRange: DateRange = [dateString, rangeEnd && isBefore(dateString, rangeEnd) ? dateString : rangeEnd];

      triggerFilterChange(newRange);
    };

    const handleModeChange = (newMode: DateSelectionMode) => {
      setSelectorMode(newMode);
      let newRange = range;

      if (newMode === 'range') {
        newRange = [range[0], addDays(range[0], 7, commonPickerProps.format)];
      }

      triggerFilterChange(newRange, newMode);
    };

    return { rangeStart, handleModeChange, handleStartChange };
  }, [range]);

  const globalMinDate = getMinDate();

  const triggerFilterChange = useCallback(
    (newRange: DateRange, mode = selectorMode) => {
      const isDateValid = mode === 'date' && newRange[0] !== null;
      const isRangeValid = mode === 'range' && newRange.every((v) => v !== null);

      if (isDateValid || isRangeValid) {
        onChange(formatValueForParent(newRange, mode));
      }
    },
    [selectorMode],
  );

  return (
    <StyledFilterContainer ref={ref} style={{ width: tabletMode ? '100%' : 'auto', gap: 8, alignItems: tabletMode ? 'initial' : 'center', flexDirection: tabletMode ? 'column' : 'row' }}>
      <Text>{t('FILTER_DELIVERIES')}</Text>
      <Row gutter={[8, 8]}>
        <Col span={mobileMode ? 24 : tabletMode ? 12 : 'unset'}>
          <Select
            style={{ width: '100%', minWidth: 110 }}
            value={selectorMode}
            onChange={handleModeChange}
            options={[
              { value: 'date', label: t('FILTER_DELIVERIES_DATE') },
              { value: 'range', label: t('FILTER_DELIVERIES_DATE_RANGE') },
            ]}
          />
        </Col>
        {selectorMode === 'date' ? (
          <Col span={mobileMode ? 24 : tabletMode ? 12 : 'unset'}>
            <DatePicker {...commonPickerProps} minDate={globalMinDate} value={rangeStart} onChange={(_, isoDate) => triggerFilterChange([isoDate as string, null])} />
          </Col>
        ) : tabletMode ? (
          <>
            {/* empty col used for special range display, do not remove */}
            {!mobileMode && <Col span={12} />}
            <Col span={mobileMode ? 24 : 12}>
              <Paragraph style={{ margin: 0 }}>{t('START_DATE')}</Paragraph>
              <DatePicker {...commonPickerProps} minDate={globalMinDate} value={rangeStart} onChange={handleStartChange} />
            </Col>
            <Col span={mobileMode ? 24 : 12}>
              <Paragraph style={{ margin: 0 }}>{t('END_DATE')}</Paragraph>
              <DatePicker {...commonPickerProps} minDate={getMinDate(range[0])} value={formatValueForPicker(range[1])} onChange={(_, isoDate) => triggerFilterChange([range[0], isoDate as string])} />
            </Col>
          </>
        ) : (
          <Col span={mobileMode ? 24 : 'unset'}>
            <RangePicker {...commonPickerProps} minDate={globalMinDate} value={range.map(formatValueForPicker) as DateRange<Dayjs>} onChange={(_, isoRange) => triggerFilterChange(isoRange)} />
          </Col>
        )}
      </Row>
    </StyledFilterContainer>
  );
});

export default DeliveriesDateFilter;

const StyledFilterContainer = styled(Space)`
  .ant-picker-input input {
    min-width: 160px;
  }
`;
