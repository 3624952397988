import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { useScreenSize, useTrimmedFields } from '@shopopop/react-hooks';
import { Card, Checkbox, Col, Form, FormInstance, Input, Row, theme } from 'antd';
import { CountryCode } from 'libphonenumber-js';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NO_SPECIAL_CHARACTERS_REGEX } from '../../../../utils/src/regex/regex.ts';
import InputEmail from '../Input/InputEmail.tsx';
import InputPhoneNumber from '../Input/InputPhoneNumber/InputPhoneNumber.tsx';

interface CreateUserFormCardProps {
  form: FormInstance;
  isNoEmail: boolean;
  country: CountryCode;
  persistCountryCode?: (countryCode: CountryCode) => void;
}

function CreateUserFormCard({ form, isNoEmail, country, persistCountryCode }: Readonly<CreateUserFormCardProps>): ReactNode {
  const { t } = useTranslation();
  const [emailRequired, setEmailRequired] = useState(!isNoEmail);
  const { width } = useScreenSize();
  const {
    token: { screenMDMax },
  } = theme.useToken();

  const formBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
  };

  // Trim the email input value
  useTrimmedFields({
    watchedInput: {
      value: Form.useWatch('email', form),
      name: 'email',
    },
    setFormValue: form.setFieldsValue,
  });

  const handleEmailCheckboxChange = (value: boolean): void => {
    setEmailRequired(value);
    form.setFieldValue('email', undefined);
    form.validateFields(['email']);
  };

  return (
    <Card title={t('RCP_INFO')}>
      <Row gutter={[16, 0]}>
        <Col {...formBreakpoints}>
          <Form.Item
            name="firstName"
            label={t('RCP_FIRSTNAME')}
            rules={[
              {
                required: true,
                message: t('FIRST_NAME_REQUIRED'),
              },
              {
                pattern: /^[^\d]*$/g,
                message: t('NUMBER_NOT_ALLOWED'),
              },
              {
                pattern: /^(?=.{1,100}$).*/g,
                message: t('FULL_NAME_LIMITE_LENGTH'),
              },
              {
                pattern: NO_SPECIAL_CHARACTERS_REGEX,
                message: t('INVALID_VALUE'),
              },
              {
                pattern: NO_EMOJI_REGEX,
                message: t('INVALID_VALUE'),
              },
            ]}
          >
            <Input data-testid="inputFirstName-FormUserCreate" />
          </Form.Item>
        </Col>

        <Col {...formBreakpoints}>
          <Form.Item
            name="lastName"
            label={t('RCP_LASTNAME')}
            rules={[
              {
                required: true,
                message: t('NAME_REQUIRED'),
              },
              {
                pattern: /^[^\d]*$/g,
                message: t('NUMBER_NOT_ALLOWED'),
              },
              {
                pattern: /^(?=.{1,100}$).*/g,
                message: t('FULL_NAME_LIMITE_LENGTH'),
              },
              {
                pattern: NO_SPECIAL_CHARACTERS_REGEX,
                message: t('INVALID_VALUE'),
              },
              {
                pattern: NO_EMOJI_REGEX,
                message: t('INVALID_VALUE'),
              },
            ]}
          >
            <Input data-testid="inputLastName-FormUserCreate" />
          </Form.Item>
        </Col>

        <Col {...formBreakpoints}>
          <InputEmail
            name={'email'}
            label={t('RCP_EMAIL')}
            dataTestid={'inputEmail-FormUserCreate'}
            options={{
              disabled: !emailRequired,
              required: emailRequired,
              noEmailCheckbox: true,
            }}
          />
          <Checkbox
            style={{ marginBottom: width > screenMDMax ? 0 : 16 }}
            onChange={(event) => {
              handleEmailCheckboxChange(!event.target.checked);
            }}
            defaultChecked={isNoEmail}
          >
            {t('RCP_NO_EMAIL')}
          </Checkbox>
        </Col>

        <Col {...formBreakpoints}>
          <InputPhoneNumber label={'RCP_TELEPHONE'} required={true} country={country} form={form} {...(persistCountryCode && { persistCountryCode })} />
        </Col>
      </Row>
    </Card>
  );
}

export default CreateUserFormCard;
