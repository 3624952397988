import { DatePicker as AntDatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDatePickerProps } from '../../interfaces/CustomDatePicker';

const { RangePicker } = AntDatePicker;

function CustomDatePicker({ form, maxDate, labelRangePicker, verticalMode, required = false }: Readonly<CustomDatePickerProps>): JSX.Element {
  const { t } = useTranslation();

  const startDateWatched = Form.useWatch('startDate', form);
  const endDateWatched = Form.useWatch('endDate', form);
  const timeRangeWatched = Form.useWatch('timeRange', form);

  useEffect(() => {
    if (startDateWatched && endDateWatched) {
      form.setFieldsValue({ timeRange: [startDateWatched, endDateWatched] });
      form.validateFields(['endDate']);
    } else if (timeRangeWatched) {
      form.setFieldsValue({ startDate: timeRangeWatched[0], endDate: timeRangeWatched[1] });
    }
  }, [startDateWatched, endDateWatched, timeRangeWatched]);

  const validateEndDate = (_: unknown, value: Dayjs) => {
    if (startDateWatched && value?.isBefore(startDateWatched)) {
      return Promise.reject(new Error(t('END_DATE_MUST_BE_AFTER_START_DATE')));
    }

    return Promise.resolve();
  };

  return verticalMode ? (
    <>
      <Form.Item label={t('START_DATE')} name="startDate" rules={
        [{ required: required, message: t('START_DATE_REQUIRED') }]
      }>
        <AntDatePicker
          style={{ width: '100%' }}
          format={'DD MMMM YYYY'}
          allowClear={false}
          minDate={dayjs().subtract(3, 'month').startOf('day')}
          maxDate={maxDate ?? dayjs().add(10, 'years').startOf('day')}
        />
      </Form.Item>
      <Form.Item label={t('END_DATE')} name="endDate" rules={
        [{ validator: validateEndDate }, { required: required, message: t('END_DATE_REQUIRED') }]}
      >
        <AntDatePicker
          style={{ width: '100%' }}
          format={'DD MMMM YYYY'}
          allowClear={false}
          minDate={startDateWatched}
          maxDate={maxDate ?? dayjs().add(10, 'years').startOf('day')}
        />
      </Form.Item>
    </>
  ) : (
    <Form.Item label={t(labelRangePicker)} name="timeRange" rules={
      [{ required: required, message: t('DATE_RANGE_REQUIRED') }]
    }>
      <RangePicker
        style={{ width: '100%' }}
        format={'DD MMMM YYYY'}
        allowClear={false}
        minDate={dayjs().subtract(3, 'month').startOf('day')}
        maxDate={maxDate ?? dayjs().add(10, 'years').startOf('day')}
      />
    </Form.Item>
  );
}

export default CustomDatePicker;
