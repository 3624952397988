import sanitizeHtml from 'sanitize-html';

/**
 * @param {string} inputString - The string to be parsed
 * @param {Array} keyValues - The array of objects containing the keys and values to be replaced
 * @param {string} keyValues[].key - The key to be replaced
 * @param {string} keyValues[].value - The value to replace the key
 * @param {string} keyValues[].url - The url to wrap the value in an anchor tag
 * @param {boolean} keyValues[].bold - The value to wrap in a bold tag
 * @param {string} keyValues[].nextContent - The content to be appended to the value
 * @param {boolean} keyValues[].openNewTab - Whether or not to open the url in a new tab
 * @return {JSX.Element}
 */
const useCustomHtml = ({ inputString, keyValues }) => {
  const constructHtml = () => {
    let result = inputString;

    keyValues?.forEach(({ key, value, url, bold, nextContent, openNewTab }) => {
      let replacement;

      if (nextContent) value = `${value}${nextContent}`;

      if (url) replacement = `<a href=${url} ${openNewTab && 'target=_blank'}>${value}</a>`;
      if (bold) replacement = `<b>${value}</b>`;
      if (url && bold) replacement = `<a href=${url} ${openNewTab && 'target=_blank'}><b>${value}</b></a>`;
      if (!url && !bold ) replacement = `<span>${value}</span>`;

      result = result.replace(key, replacement);
    });

    return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(result, {
      allowedTags: ['a', 'b', 'span', 'br'],
      allowedAttributes: {
        a: ['href', 'target'],
        b: [],
        span: [],
      },
      allowedSchemes: ['http', 'https'],
    }), }} />;
  };

  return constructHtml();
};

export default useCustomHtml;
