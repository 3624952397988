import fetchData from '../fetchData';
import { getThrowErrorMessage } from '../../messages';
import dayjs from 'dayjs';

/**
 * Reads deliveries from the API based on the provided filters.
 *
 * @param {string} search - The search query to filter the deliveries by.
 * @param {object} signal - The signal param to abort fetch
 * @return {Promise<Object>} - The count and list of deliveries.
 * @throws {Error} - If there is an error while fetching the deliveries.
 */
export const readDeliveries = async(search, signal) => {
  const params = new URLSearchParams(search);

  // Date time slot for API
  if (params.get('timeSlot')) {
    const timeSlotRanges = {
      today: {
        from: dayjs().startOf('day').toISOString(),
        to: dayjs().endOf('day').toISOString(),
      },
      tomorrow: {
        from: dayjs().add(1, 'day').startOf('day').toISOString(),
        to: dayjs().add(1, 'day').endOf('day').toISOString(),
      },
      afterTomorrow: {
        from: dayjs().add(2, 'day').startOf('day').toISOString(),
        to: dayjs().add(2, 'day').endOf('day').toISOString(),
      },
    };
    params.set('deliveryStartAfter', timeSlotRanges[params.get('timeSlot')].from);
    params.set('deliveryStartBefore', timeSlotRanges[params.get('timeSlot')].to);
    params.delete('timeSlot');
  }

  // Order for MySQL
  const order = params.get('order');
  if (order === 'ascend') params.set('order', 'ASC');
  if (order === 'descend') params.set('order', 'DESC');

  // Default page and limit
  if (!params.get('page')) params.set('page', 1);
  if (!params.get('limit')) params.set('limit', 10);

  if (!params.get('label')) params.set('label', 'ongoing');

  // Api URL by tab selected
  const labelMatches = {
    'ongoing': '',
    'inactive': '',
    'action-needed': '/action-needed',
  };

  const apiUrl = `${process.env.REACT_APP_API_BO}/deliveries${labelMatches[params.get('label')]}?${params.toString()}`;
  const method = 'GET';

  try {
    const response = await fetchData({ apiUrl, method, signal });
    return {
      count: response.data.count,
      list: response.data.list,
      success: response.ok,
    };
  } catch (error) {
    if (error.name === 'AbortError') {
      return {
        aborted: true,
      };
    } else {
      throw new Error(getThrowErrorMessage({ functionName: 'readDeliveries', error }));
    }
  }
};
